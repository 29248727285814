
export default {
  // new Intl.NumberFormat('en-US',{ style: 'currency', currency: 'USD' }).format(money); // '$100.00'
  // currentMonthTotal: state => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Math.round(state.currentMonthTotal)),
  currentMonthTotal: state => state.currentMonthTotal,
  currentMonthLabel: state => state.currentMonthLabel,
  currentMonthIncrease: state => state.currentMonthIncrease,
  currentMonthAvg3Months: state => state.currentMonthAvg3Months,
  currentDomesticVoice: state => state.currentDomesticVoice,
  currentDomesticData: state => state.currentDomesticData,
  currentOverseasData: state => state.currentOverseasData,
  mobileCount: state => state.mobileCount + '',
  currentMonthActive: state => state.currentMonthActive,
  acctCount: state => state.acctCount + '', // column h ie acct
  cidnCount: state => state.cidnCount, // column c ie cidn
  activeCIDNCount: state => 'CIDN Count ' + state.cidnCount,
  dataUsage: state => state.dataUsage,
  refreshRequired: state => state.refreshRequired,
  billTotal12Months: state => state.billTotal12Months,
  deviceSummaryList: state => state.deviceSummaryList,
  equipmentSummaryMake: state => state.equipmentSummaryMake,
  equipmentSummaryType: state => state.equipmentSummaryType,
  equipmentSummaryModel: state => state.equipmentSummaryModel,
  flexiPlanSummaryList: state => state.flexiPlanSummaryList,
  shareablePlanSummaryList: state => state.shareablePlanSummaryList,
  nonShareablePlanSummaryList: state => state.nonShareablePlanSummaryList,
  topTenLoading: state => state.topTenLoading,
  topTenOverseasCall: state => state.topTenOverseasCall,
  topTenDomesticCall: state => state.topTenDomesticCall,
  topTenDomesticVoiceCall: state => state.topTenDomesticVoiceCall,
  topTenDomesticVoiceDuration: state => state.topTenDomesticVoiceDuration,
  topTenDomesticUsage: state => state.topTenDomesticUsage,
  // topTenDomesticDuration: state => state.topTenDomesticDuration,
  topTenDomesticBillTotal: state => state.topTenDomesticBillTotal,
  domesticVoiceTotal12Months: state => state.domesticVoiceTotal12Months,
  domesticDataTotal12Months: state => state.domesticDataTotal12Months,
  dataAllowance: state=> state.dataAllowance,
}
