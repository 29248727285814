import { set, toggle } from '@/utils/vuex'

export default {
  setTopTenMake: set('topTenMake'),
  setTopTenModel: set('topTenModel'),
  setTopTenType: set('topTenType'),
  setRefreshRequired: set('refreshRequired'),
  setEquipmentTableFilter: set('equipmentTableFilter'),
  setEquipmentChartFilter: set('equipmentChartFilter'),
  setSnTableFilter: set('snTableFilter'),
  setSnFilter: set('snFilter')
}
