export default {
  // Here we will create a getter
  appDrawer: (state) => state.drawer,
  barColor: (state) => state.barColor,
  barImage: (state) => state.barImage,
  progress: (state) => state.progress,
  customerInfo: (state) => state.customerInfo,
  refreshRequired: (state) => state.refreshRequired,
  snCount: (state) => state.customerInfo.snCount,
  importDate: (state) => state.customerInfo.importDate,
  fileName: (state) => state.customerInfo.fileName,
  categories: (state) => state.customerInfo.categories,
  globalAccounts: (state) => state.customerInfo.globalAccounts,
  globalFilter: (state) => state.globalFilter,
  applyingGlobalFilter: (state) => state.applyingGlobalFilter,
  dataLoaded: (state) => state.dataLoaded,
  dataLoadRequired: (state) => state.dataLoadRequired,
  buybackAvailable: (state) => state.buybackAvailable,
  showBuybackPrice: (state) => state.showBuybackPrice
}
