
const versionNumber = process.env.VUE_APP_VERSION
const encryptcredential = process.env.VUE_APP_ENCRYPT_CREDENTIAL

const ConfigProperties = {
  getVersion () {
    console.log('Configuration versionNumber is ' + versionNumber)
    return versionNumber
  },
  getCredentials() {
    return encryptcredential
  }
}

export default ConfigProperties
