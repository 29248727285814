export default {
  drawer: null,
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
  progress: false,
  refreshRequired: false,
  dataLoaded: false, // for displaying snackbar when data is loaded
  customerInfo: {},
  globalFilter: 'ALL',
  applyingGlobalFilter: false,
  dataLoadRequired: false, // indicate if this is the first use of this software eg no data uploaded or version is changed
  buybackAvailable: false,
  showBuybackPrice: false
}
