import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css'
import { sync } from 'vuex-router-sync'
import './plugins/firebase'
import './plugins/base'
import './plugins/chartist'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueApexCharts from 'vue-apexcharts'
import VueGtag from 'vue-gtag'
import JsonExcel from 'vue-json-excel'

sync(store, router)

Vue.config.productionTip = false

Vue.component('apexchart', VueApexCharts)
Vue.component('downloadExcel', JsonExcel)
Vue.use(VueGtag, {
  config: { id: 'UA-165558912-1' },
  params: {
    send_page_view: false
  }
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')

// console.group('process.env.AppVersion')
// console.log(process.env.AppVersion)
// console.groupEnd()
