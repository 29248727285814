import moment from 'moment'
import { isEmpty } from 'lodash'
import {
  getSnUsageMonthDb,
  getSnDb,
  getEquipmentSummaryDb,
  getCustomerInfo,
  getSnCustomerInfoDb,
} from './PouchDao'
import { findAllSn } from './PouchDaoReport'

/**
 *  filterOptions to contain { key: null, deviceType: null, make: null, deviceName: null },
 * @param filterOptions
 * @returns {Promise<*>}
 */
async function findPlanEquipmentSummary (filterOptions) {
  // group the billtotal by months and get the total...
  console.log('findPlanEquipmentSummary started with filterOptions ', filterOptions)
  // console.log('equipmentTableFilter started ', equipmentTableFilter)

  // Object.keys(yourObject).length === 0
  let filterRequired = true
  if (isEmpty(filterOptions)) {
    filterRequired = false
  }
  return getEquipmentSummaryDb().then((equipmentSummaryDb) => {
    return equipmentSummaryDb.allDocs({
      include_docs: true,
    })
  }).then((result) => {
    let planResult = []
    //
    // need to grab the planMap
    result.rows.forEach(record => {
      const planMap = record.doc.planMap
      // const plans = [...planMap].map(([key, value]) => ({...value, ...{ planName: key }}))
      const plans = [...planMap].map(([key, value]) => ({...value}))
      planResult.push(plans)
    })
    const flattenResult = planResult.flat(1)
    // console.log('findPlanEquipmentSummary planResult is ', flattenResult)
    return flattenResult
  }).then((result) => {
    let resultList = []
    result.forEach(equipmentSummary => {
      if (filterRequired) {
        if (filterOptions.planName) {
          if (equipmentSummary.planName === filterOptions.planName) {
            resultList.push(equipmentSummary)
          }
        }
        if (filterOptions.category === 'deviceType') {
          if (equipmentSummary.deviceType === filterOptions.label) {
            resultList.push(equipmentSummary)
          }
        }
        if (filterOptions.category === 'make') {
          if (equipmentSummary.make === filterOptions.label) {
            resultList.push(equipmentSummary)
          }
        }
        if (filterOptions.category === 'deviceName') {
          if (equipmentSummary.deviceName === filterOptions.label) {
            resultList.push(equipmentSummary)
          }
        }
      } else {
        resultList.push(equipmentSummary)
      }
    })
    return { equipmentSummaryList: resultList }
  })
}

async function getFlexiPlanList () {

  console.log('getFlexiPlanList is starting')
  let flexiPlanMap = new Map()
  let shareablePlanMap = new Map()
  let nonShareablePlanMap = new Map()

  const customerInfo = await getCustomerInfo()

  //get All data from sn database
  await findAllSn({ include_docs: true }).then( sn=>{
    sn.forEach(r =>{
      if (customerInfo.biltzFileVersion == '1.0'){
        // flexi and sharable plan map update for Blitz Ver 1.0
        insertItemIntoPlanMap(flexiPlanMap, r.flexiPlanName, r.flexPlanFee)
        insertItemIntoPlanMap(shareablePlanMap, r.shareablePlanName, r.shareablePlanFee)
      }
      else{
        // flexi and sharable plan map update for Blitz Ver 2.0
        
        insertItemIntoPlanMap(flexiPlanMap, r.flexiPlanName, r.flexPlanFee)
        insertItemIntoPlanMap(shareablePlanMap, r.shareablePlanName, r.shareablePlanFee)
        insertItemIntoPlanMap(shareablePlanMap, r.shareablePlanName2, r.shareablePlanFee2)
        insertItemIntoPlanMap(shareablePlanMap, r.shareableBonusPlanName1, r.shareableBonusPlanFee1)
        insertItemIntoPlanMap(shareablePlanMap, r.shareableBonusPlanName2, r.shareableBonusPlanFee2)     
        insertItemIntoPlanMap(nonShareablePlanMap, r.nonShareablePlanName1, r.nonShareablePlanFee1)
        insertItemIntoPlanMap(nonShareablePlanMap, r.nonShareablePlanName2, r.nonShareablePlanFee2)
        insertItemIntoPlanMap(nonShareablePlanMap, r.nonShareableBonusPlanName1, r.nonShareableBonusPlanFee1)
        insertItemIntoPlanMap(nonShareablePlanMap, r.nonShareableBonusPlanName2, r.nonShareableBonusPlanFee2)
        
      }
    })
    
    return [ flexiPlanMap, shareablePlanMap, nonShareablePlanMap ]
  })

  const flexiPlanList = [...flexiPlanMap.values()]  
  const shareablePlanList = [...shareablePlanMap.values()]  
  const nonShareablePlanList = [...nonShareablePlanMap.values()]  
  return [ flexiPlanList, shareablePlanList, nonShareablePlanList ]
}

/**
 * 
 * @param planMap 
 * @param planName 
 * @param {Map<any, any>} planFee 
 * @returns {Map<any, any>}
 */
 function insertItemIntoPlanMap(planMap, planName, planFee){
  if (!isEmpty(planName)) {
    if (planMap.get(planName)) { // key exists
      let itemPlan = planMap.get(planName)
      itemPlan.qty += 1
      itemPlan.total += planFee
    } else { // key does not exist
      let itemPlan = {
        _id: planName,
        planName: planName,
        qty: 1,
        fee: planFee,
        total: planFee,
      }
      planMap.set(planName, itemPlan)
    }
  }  
}

export {
  findPlanEquipmentSummary, 
  getFlexiPlanList,
}
