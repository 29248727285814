import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/**
 * Refer to @/views/blitz/components/core/Drawer.vue to show this menu options on the page.
 * @type {VueRouter}
 */
const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/views/blitz/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          meta: { requiresAuth: true },
          component: () => import('@/views/blitz/Dashboard'),
        },
        {
          name: 'LoadReport',
          path: 'loadreport/:sn',
          props: true,
          component: () => import('@/views/blitz/LoadReport'),
        },
        {
          name: 'Usage',
          path: 'pages/usage',
          meta: { requiresAuth: true },
          component: () => import('@/views/blitz/pages/Usage'),
        },
        {
          name: 'Equipment',
          path: 'pages/equipment',
          meta: { requiresAuth: true },
          component: () => import('@/views/blitz/pages/Equipment'),
        },
        {
          name: 'ServiceNumberPage',
          path: 'pages/serviceNumberPage/:sn', // refer to Search.vue who calls this route
          // path: 'pages/serviceNumberPage', // refer to Search.vue who calls this route
          props: true,
          meta: { requiresAuth: true },
          component: () => import('@/views/blitz/pages/ServiceNumberPage'),
        },
        {
          name: 'ConfiguredOptions',
          path: 'pages/configuredOptions',
          meta: { requiresAuth: true },
          component: () => import('@/views/blitz/pages/ConfiguredOptions'),
        },
        {
          name: 'Plans',
          path: 'pages/plans',
          meta: { requiresAuth: true },
          component: () => import('@/views/blitz/pages/Plans'),
        },
        {
          name: 'ServiceDetails',
          path: 'pages/serviceDetails',
          meta: { requiresAuth: true },
          component: () => import('@/views/blitz/pages/ServiceDetails'),
        },
        {
          name: 'CostCenterManagement',
          path: 'pages/costcentermanagement',
          props: true,
          meta: { requiresAuth: true },
          component: () => import('@/views/blitz/pages/CostCentreManagement'),
        },
        {
          name: 'Settings',
          path: 'pages/settings',
          meta: { requiresAuth: true },
          component: () => import('@/views/blitz/pages/Settings'),
        },
      ],
    },
    // {
    //   path: '/pages',
    //   component: () => import('@/views/pages/Index'),
    //   children: [
    //     {
    //       name: 'Login',
    //       path: 'login',
    //       component: () => import('@/views/pages/Login'),
    //     },
    //   ],
    // },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // const isAuthenticated = userDao.isAuthenticated()
  // if (isAuthenticated) {
  //   console.log('isAuthenticated', isAuthenticated)
  // }
  // if (requiresAuth && !isAuthenticated) {
  //   console.log('router requires authentication')
  //   next('/pages/login')
  // } else {
  //   next()
  // }
  next()
})

export default router
