export default {
  // Here we will create action to populate
  async refreshRequired ({ commit }, value) {
    commit('setRefreshRequired', value)
  },
  async refreshSelectedTab ({ commit }, value) {
    console.log('refreshSelectedTab', value)
    if (value.category === 'DOMESTIC DATA') {
      commit('setDomesticDataCategory', value.dataType)
    } else if (value.category === 'DOMESTIC') {
        commit('setDomesticVoiceCategory', value)
    } else if (value.category === 'DOMESTIC VOICE') {
      commit('setDomesticVoiceCategory', value)
    } else if (value.category === 'OVERSEAS') {
      commit('setOverseasDataCategory', value.dataType)
    } else if (value.category === 'NOUSAGE') {
      commit('setNoUsageCategory', value.dataType)
    }  
    commit('setSelectedTab', value.category)
  },
  async refreshDataCategory ({ commit }, value) {
    commit('setDomesticDataCategory', value)
  },
  async refreshOverseasDataCategory ({ commit }, value) {
    commit('setOverseasDataCategory', value)
  },
  async refreshDomesticVoiceCategory ({ commit }, value) {
    commit('setDomesticVoiceCategory', value)
  },
  async updateDataTable ({ commit }, value) {
    commit('setDomesticDataTable', value)
  },
  async updateDataTableLabels ({ commit }, value) {
    commit('setDomesticDataTableLabels', value)
  },
  async updateSnFilter ({ commit }, value) {
    commit('setSnFilter', value)
  },
  async updateDefaultTopTenMonth ({ commit }, value) {
    commit('setDefaultTopTenMonth', value)
  },
  async refreshNoUsageCategory ({ commit }, value) {
    commit('setNoUsageCategory', value)
  },
}
