// import PouchDb from 'pouchdb';
import PouchDB from 'pouchdb-browser'
// import findPlugin from 'pouchdb-find'
// import moment from 'moment'
import ConfigProperties from '@/api/ConfigurationProperties'
// import config from 'config'

// import { getTimestampIdentifier } from 'vuetify/src/components/VCalendar/util/timestamp'

PouchDB.plugin(require('crypto-pouch'))
// const PouchDB = require('pouchdb-browser');
// PouchDB.plugin(require('pouchdb-find'));
// PouchDB.plugin(findPlugin)

import FetchReportHelper from '@/api/FetchReportHelper'

// ************ IMPORTANT  *******
// **** IMPORTANT make sure these database is repeated for acccount filter db) *******
let snCustomerInfoDb = new PouchDB('snCustomerInfo')
let snDb = new PouchDB('sn')

let snUsageDb = new PouchDB('snUsage')
let snUsageMonthDb = new PouchDB('snUsageMonth') // used for storing usage with 12 columns as months eg Jan to Dec
let equipmentSummaryDb = new PouchDB('equipmentSummaryDb')
let snCostCentreDb = new PouchDB('snCostCentreDb')
let deviceInContractDb = new PouchDB('deviceInContractDb')

// ************ IMPORTANT ***********

// here is a replica of the databases for filtering accounts

let acctSnCustomerInfoDb = new PouchDB('acctSnCustomerInfo')
let acctSnDb = new PouchDB('acctSn')
let acctSnUsageDb = new PouchDB('acctSnUsage')
let acctSnUsageMonthDb = new PouchDB('acctSnUsageMonth') // used for storing usage with 12 columns as months eg Jan to Dec
let acctEquipmentSummaryDb = new PouchDB('acctEquipmentSummaryDb')
let acctSnCostCentreDb = new PouchDB('acctSnCostCentreDb')

let buybackDb = new PouchDB('buybackDb')

const globalFilterId = '_local/globalFilter' // _local is special flag for storing local document
const globalFilterALL = 'ALL'

/**
 * delete all database and create a new one...
 * @returns {Promise<void>}
 */
async function deleteDatabase() {
  console.log('deleteDatabase started')
  return snUsageDb
    .destroy()
    .then(function () {
      // database destroyed
      console.log('snUsageDb destroyed')
      snUsageDb = new PouchDB('snUsage')
      return 1
    })
    .then(function () {
      return acctSnUsageDb.destroy()
    })
    .then(function () {
      acctSnUsageDb = new PouchDB('acctSnUsage')
      return 1
    })
    .then(function () {
      console.log('snUsageMonthDb destroyed')
      return snUsageMonthDb.destroy()
    })
    .then(function () {
      snUsageMonthDb = new PouchDB('snUsageMonth')
      if (process.env.NODE_ENV === 'development') {
        return 1
      } else {
        const password = ConfigProperties.getCredentials()
        return snUsageMonthDb.crypto(password).then(() => {
          return 1
        })
      }
    })
    .then(function () {
      console.log('acctSnUsageMonthDb destroyed')
      return acctSnUsageMonthDb.destroy()
    })
    .then(function () {
      acctSnUsageMonthDb = new PouchDB('acctSnUsageMonth')
      if (process.env.NODE_ENV === 'development') {
        return 1
      } else {
        const password = ConfigProperties.getCredentials()
        return acctSnUsageMonthDb.crypto(password).then(() => {
          return 1
        })
      }
    })
    .then(function () {
      console.log('snDb destroyed')
      return snDb.destroy()
    })
    .then(function () {
      snDb = new PouchDB('sn')
      if (process.env.NODE_ENV === 'development') {
        return 1
      } else {
        //encrypt sn table
        const password = ConfigProperties.getCredentials()
        return snDb.crypto(password).then(() => {
          return 1
        })
      }
    })
    .then(function () {
      return acctSnDb.destroy()
    })
    .then(function () {
      acctSnDb = new PouchDB('acctSn')
      if (process.env.NODE_ENV === 'development') {
        return 1
      } else {
        const password = ConfigProperties.getCredentials()
        return acctSnDb.crypto(password).then(() => {
          return 1
        })
      }
    })
    .then(function () {
      console.log('equipmentSummaryDb destroyed')
      return equipmentSummaryDb.destroy()
    })
    .then(function () {
      equipmentSummaryDb = new PouchDB('equipmentSummaryDb')
      return 1
    })
    .then(function () {
      console.log('deviceInContractDb destroyed')
      return deviceInContractDb.destroy()
    })
    .then(function () {
      deviceInContractDb = new PouchDB('deviceInContractDb')
      return 1
    })
    .then(function () {
      return acctEquipmentSummaryDb.destroy()
    })
    .then(function () {
      acctEquipmentSummaryDb = new PouchDB('acctEquipmentSummaryDb')
      return 1
    })
    .then(function () {
      console.log('snCustomerInfoDb destroyed')
      return snCustomerInfoDb.destroy()
    })
    .then(function () {
      snCustomerInfoDb = new PouchDB('snCustomerInfo')
      return 1
    })
    .then(function () {
      return acctSnCustomerInfoDb.destroy()
    })
    .then(function () {
      acctSnCustomerInfoDb = new PouchDB('acctSnCustomerInfo')
      console.log('deleteDatabase ended')
      return 1
    })
    .catch(function (err) {
      // error occurred
      console.log(err)
    })
}

async function deleteBuybackDatabase() {
  console.log('deleteBuybackDatabase started')
  return buybackDb.destroy().then(function () {
    // buybackDb destroyed
    console.log('buybackDb destroyed')
    buybackDb = new PouchDB('buybackDb')
    return 1
  })
}

async function getCustomerInfo() {
  return snCustomerInfoDb.allDocs({ include_docs: true, key: 'customerInfo', limit: 1 }).then((result) => {
    if (result.rows.length > 0) {
      return result.rows[[0]].doc
    } else {
      console.warn('no customer recorded')
      return {}
    }
  })
}

// this will remove the global filter if account number is ALL
async function setGlobalFilter(accountNumber) {
  // console.log('setGlobalFilter to ' + accountNumber)
  return currentFilter(accountNumber)
}

function currentFilter(accountNumber) {
  if (typeof currentFilter.accountNumber === 'undefined') {
    currentFilter.accountNumber = 'ALL'
  }
  if (accountNumber === 'GET') {
    return currentFilter.accountNumber
  }
  currentFilter.accountNumber = accountNumber
  return currentFilter.accountNumber
}

// check what is the current global filter
async function getCurrentGlobalFilter() {
  const currentAccountFilter = currentFilter('GET')
  console.log('getCurrentGlobalFilter called filter is ', currentAccountFilter)
  return { value: currentAccountFilter }
}

// if account is ALL do not copy....
async function applyGlobalFilter(accountNumber) {
  console.log('applyGlobalFilter called with ' + accountNumber)
  if (accountNumber === globalFilterALL) {
    return clearAcctDatabase().then(() => {
      console.log('applyGlobalFilter clearAcctDatabase for ' + accountNumber)
      return setGlobalFilter(accountNumber)
    })
  } else {
    return clearAcctDatabase()
      .then(function () {
        console.log('applyGlobalFilter acctSnDb started to filter and insert rows')
        return snDb.allDocs({ include_docs: true })
      })
      .then(function (result) {
        return filterByAccount(result.rows, accountNumber)
      })
      .then(function (result) {
        console.log('inserting ' + result.length + ' rows into acctSnDb database ')
        return acctSnDb.bulkDocs(result) // bulk insert rows??
      })
      .then(function () {
        console.log('applyGlobalFilter acctSnUsageDb started to filter and insert rows')
        return snUsageDb.allDocs({ include_docs: true })
      })
      .then(function (result) {
        return filterByAccount(result.rows, accountNumber)
      })
      .then(function (result) {
        console.log('inserting ' + result.length + ' rows into acctSnUsageDb database ')
        return acctSnUsageDb.bulkDocs(result) // bulk insert rows??
      })
      .then(function () {
        console.log('applyGlobalFilter acctSnUsageMonthDb started to filter and insert rows')
        return snUsageMonthDb.allDocs({ include_docs: true })
      })
      .then(function (result) {
        return filterByAccount(result.rows, accountNumber)
      })
      .then(function (result) {
        console.log('inserting ' + result.length + ' rows into acctSnUsageMonthDb database ')
        return acctSnUsageMonthDb.bulkDocs(result) // bulk insert rows??
      })
      .then(function () {
        console.log('applyGlobalFilter acctEquipmentSummaryDb started to filter and insert rows')
        return equipmentSummaryDb.allDocs({ include_docs: true })
      })
      .then(function (result) {
        return filterEquipmentSummaryByAccount(result.rows, accountNumber)
      })
      .then(function (result) {
        console.log('inserting ' + result.length + ' rows into acctSnUsageMonthDb database ')
        return acctEquipmentSummaryDb.bulkDocs(result) // bulk insert rows??
      })
      .then(() => {
        console.log('applyGlobalFilter database refresh ended setting global filter to ' + accountNumber)
        return setGlobalFilter(accountNumber)
      })
      .then(function () {
        console.log('applyGlobalFilter deviceInContractDb started to filter and insert rows')
        return deviceInContractDb.allDocs({ include_docs: true })
      })
  }
}

function filterByAccount(rows, accountNumber) {
  let filteredList = []
  rows.forEach((row) => {
    if (row.doc.acct === accountNumber) {
      let matchingRow = row.doc
      matchingRow._id = row.id
      delete matchingRow._rev // need to remove _rev from the matchingRow object otherwise insert will not occur
      filteredList.push(matchingRow)
    }
  })
  return filteredList
}

// Equipment Summary has a list of acctNumbers
// TODO group equipment summary by account rough cut need to better calculate equipment summary
function filterEquipmentSummaryByAccount(rows, accountNumber) {
  let filteredList = []
  rows.forEach((row) => {
    let accountSummaryMap = row.doc.acctSummaryMap
    let accountSummary = accountSummaryMap.get(accountNumber)
    if (accountSummary) {
      accountSummary._id = row.id
      filteredList.push(accountSummary)
    }
  })
  return filteredList
}

// function to reset the account databases
async function clearAcctDatabase() {
  console.log('clearAcctDatabase started')
  return acctSnDb
    .destroy()
    .then(function () {
      // database destroyed
      console.log('snUsageDb destroyed')
      acctSnDb = new PouchDB('acctSn')
      if (process.env.NODE_ENV === 'development') {
        return 1
      } else {
        const password = ConfigProperties.getCredentials()
        return acctSnDb.crypto(password).then(() => {
          return 1
        })
      }
    })
    .then(function () {
      return acctSnUsageDb.destroy()
    })
    .then(function () {
      acctSnUsageDb = new PouchDB('acctSnUsage')
      return 1
    })
    .then(function () {
      return acctSnUsageMonthDb.destroy()
    })
    .then(function () {
      acctSnUsageMonthDb = new PouchDB('acctSnUsageMonth')
      if (process.env.NODE_ENV === 'development') {
        return 1
      } else {
        const password = ConfigProperties.getCredentials()
        return acctSnUsageMonthDb.crypto(password).then(() => {
          return 1
        })
      }
    })
    .then(function () {
      return acctEquipmentSummaryDb.destroy()
    })
    .then(function () {
      acctEquipmentSummaryDb = new PouchDB('acctEquipmentSummaryDb')
      console.log('clearAcctDatabase ended')
      return 1
    })
    .then(function () {
      // delete global filter key....

      return 1
    })
}

async function insertTopTenInfo(topTenInfo) {
  console.log('insertTopTenInfo started ')
  // deletes all first...
  const infoKey = 'topTenInfo'
  return snCustomerInfoDb
    .allDocs({ key: infoKey, include_docs: true })
    .then((allDocs) => {
      return allDocs.rows.map((row) => {
        return { _id: row.id, _rev: row.doc._rev, _deleted: true }
      })
    })
    .then((deleteDocs) => {
      return snCustomerInfoDb.bulkDocs(deleteDocs)
    })
    .then((result) => {
      topTenInfo._id = infoKey
      return snCustomerInfoDb.put(topTenInfo)
    })
    .then((result) => {
      console.log('insertTopTenInfo ended')
      return result
    })
    .catch((err) => {
      console.log(err)
    })
}

async function insertDashboardInfo(dashboardInfo) {
  console.log('insertDashboardInfo started ')
  // deletes all first...

  const infoKey = 'dashboardInfo'
  return snCustomerInfoDb
    .allDocs({ key: infoKey, include_docs: true })
    .then((allDocs) => {
      return allDocs.rows.map((row) => {
        return { _id: row.id, _rev: row.doc._rev, _deleted: true }
      })
    })
    .then((deleteDocs) => {
      return snCustomerInfoDb.bulkDocs(deleteDocs)
    })
    .then((result) => {
      dashboardInfo._id = infoKey
      return snCustomerInfoDb.put(dashboardInfo)
    })
    .then((result) => {
      console.log('insertDashboardInfo ended')
      return result
    })
    .catch((err) => {
      console.log(err)
    })
}

async function insertCustomerInfo(
  businessUnit,
  customerName,
  billingName,
  ucidn,
  bill12MonthsGrandTotal,
  fileName,
  snCount,
  categories,
  earliest,
  yearMonthSelectList,
  latest,
  globalAccounts,
  partner,
  biltzFileVersion
) {
  console.log('insertCustomerInfo started categories ' + categories.length)
  // deletes all first...
  const infoKey = 'customerInfo'
  const versionNumber = ConfigProperties.getVersion()
  return snCustomerInfoDb
    .allDocs({ key: infoKey, include_docs: true })
    .then((allDocs) => {
      return allDocs.rows.map((row) => {
        return { _id: row.id, _rev: row.doc._rev, _deleted: true }
      })
    })
    .then((deleteDocs) => {
      return snCustomerInfoDb.bulkDocs(deleteDocs)
    })
    .then((result) => {
      const importedDate = new Date()
      return snCustomerInfoDb.put({
        _id: infoKey,
        appVersion: versionNumber,
        customerName: customerName,
        businessUnit: businessUnit,
        billingName: billingName,
        ucidn: ucidn,
        bill12MonthsGrandTotal: bill12MonthsGrandTotal,
        snCount: snCount,
        fileName: fileName,
        importDate: importedDate,
        earliest: earliest,
        latest: latest,
        yearMonthSelectList: yearMonthSelectList,
        categories: categories,
        globalAccounts: globalAccounts,
        blitzLogo: partner.partnerBlitzLogo,
        blitzIcon: partner.partnerBlitzIcon,
        blitzLink: partner.accessKey,
        biltzFileVersion: biltzFileVersion,
        navbarTitle: partner.navbarTitle,
        sidebarColor: partner.sidebarColor,
        activeButtonColor: partner.activeButtonColor,
        sidebarTextColor: partner.sidebarTextColor
      })
    })
    .then((result) => {
      console.log('insertCustomerInfo ended', result)
      return result
    })
    .catch((err) => {
      console.log(err)
    })
}

async function bulkInsertSn(customerNumber, accountNumber, serviceNumbers) {
  return snDb
    .bulkDocs(serviceNumbers)
    .then((result) => {
      // console.log(res);
      console.log('bulkInsertSn Account ' + accountNumber + ' service numbers ' + serviceNumbers.length + ' inserted OK')
      return result
      // snDb.allDocs().then(entries => console.log(entries.rows.length));
    })
    .catch((err) => {
      console.error(err)
    })
}

/**
 *
 * @param {Map<string, {price: integer}>} rows
 * @returns {Promise<*>}
 */
async function bulkInsertBuyback(rows) {
  return buybackDb
    .bulkDocs(rows)
    .then((result) => {
      console.log('bulkInsertBuyback inserted successfully')
      return result
    })
    .catch((err) => {
      console.error(err)
      throw new Error(err)
    })
}

async function bulkInsertEquipmentSummary(customerNumber, accountNumber, equipmentSummaryList) {
  console.log('bulkInsertEquipmentSummary started')
  return chunk(equipmentSummaryList, 1200).then((chunkedArray) => {
    // perform a parallel bulk insert
    let asyncBulkInsertPromises = []
    chunkedArray.forEach((usageList) => {
      // cant do concurrent bulk insert
      // option 2 try synch
      //
      let asyncBulkInsertPromise = equipmentSummaryDb
        .bulkDocs(usageList)
        .then((result) => {
          console.log('bulkInsertEquipmentSummary Account ' + accountNumber + ' billMonth ' + usageList.length + ' inserted OK')
          return result
        })
        .catch((err) => {
          console.error(err)
        })
      // need to commit this before going to the next...
      asyncBulkInsertPromises.push(asyncBulkInsertPromise)
      // console.log('bulkInsertSnUsage chunked list size is ' + usageList.length + ' completed')
    })
    return Promise.all(asyncBulkInsertPromises)
  })
}

/**
 * @returns {Promise<Awaited<unknown>[]>}
 * @param deviceInContractList
 */
async function bulkInsertDeviceInContract (deviceInContractList) {
  console.log('bulkInsertDeviceInContract started')
  return chunk(deviceInContractList, 1200).then((chunkedArray) => {
    // perform a parallel bulk insert
    const asyncBulkInsertPromises = []
    chunkedArray.forEach((itemList) => {
      // cant do concurrent bulk insert
      // option 2 try synch
      //
      const asyncBulkInsertPromise = deviceInContractDb
        .bulkDocs(itemList)
        .then((result) => {
          return result
        })
        .catch((err) => {
          console.error(err)
        })
      asyncBulkInsertPromises.push(asyncBulkInsertPromise)
    })
    return Promise.all(asyncBulkInsertPromises)
  })
}

/**
 * Note the usages can be in tens of thousands cause index transaction errors
 * need to chunk it...
 *
 * @param customerNumber
 * @param accountNumber
 * @param usages
 * @returns {Promise<postcss.Result|any>}
 */
async function bulkInsertSnUsage(customerNumber, accountNumber, usages) {
  console.log('bulkInsertSnUsage started') // 48 rows per mobile
  if (usages.length > 76800) {
    console.log('bulkInsertSnUsage via sequential mode') // 48 rows per mobile
    return chunk(usages, 1200).then((chunkedArray) => {
      return processInSequence(chunkedArray, snUsageDb.bulkDocs)
    })
  } else {
    // perform a parallel bulk insert
    console.log('bulkInsertSnUsage via async mode') // 48 rows per mobile
    return chunk(usages, 1200).then((chunkedArray) => {
      let asyncBulkInsertPromises = []
      chunkedArray.forEach((usageList) => {
        let asyncBulkInsertPromise = snUsageDb
          .bulkDocs(usageList)
          .then((result) => {
            console.log('bulkInsertSnUsage Account ' + accountNumber + ' usages ' + usageList.length + ' inserted OK')
            return result
          })
          .catch((err) => {
            console.error(err)
          })
        // need to commit this before going to the next...
        asyncBulkInsertPromises.push(asyncBulkInsertPromise)
      })
      return Promise.all(asyncBulkInsertPromises)
    })
  }
}

const processInSequence = async (iterable, action) => {
  let i = 1
  for (const x of iterable) {
    await action(x)
    console.log('processInSequence processed ' + i++ + ' of ' + iterable.length)
  }
  console.log('processInSequence completed  ' + iterable.length)
  return 1
}

function processChunk(accountNumber, usageList) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, 2000)
  })
}

function resolveAfter2Seconds(x) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(x)
    }, 2000)
  })
}

async function f1() {
  let x = await resolveAfter2Seconds(10)
  let y = await processChunk('asdasd', x)
  console.log(x) // 10
}

async function bulkInsertSnMonthUsage(customerNumber, accountNumber, usages) {
  console.log('bulkInsertSnMonthUsage started to process ' + usages.length + ' usages') // 48 rows per mobile
  return chunk(usages, 1200).then((chunkedArray) => {
    // perform a parallel bulk insert
    let asyncBulkInsertPromises = []
    chunkedArray.forEach((usageList) => {
      // cant do concurrent bulk insert
      // option 2 try synch
      //
      let asyncBulkInsertPromise = snUsageMonthDb
        .bulkDocs(usageList)
        .then((result) => {
          // console.log('bulkInsertSnMonthUsage Account ' + accountNumber + ' usages ' + usageList.length + ' inserted OK')
          return result
        })
        .catch((err) => {
          console.error(err)
        })
      asyncBulkInsertPromises.push(asyncBulkInsertPromise)
      // console.log('bulkInsertSnMonthUsage chunked list size is ' + usageList.length)
    })
    return Promise.all(asyncBulkInsertPromises)
    // return 'done'
  })
}

async function chunk(array, size) {
  const chunkedArr = []
  let index = 0
  while (index < array.length) {
    chunkedArr.push(array.slice(index, size + index))
    index += size
  }
  return chunkedArr
}

/**
 * delete all rows...
 *
 * @param customerNumber
 * @param accountNumber
 * @returns {Promise<unknown[]>}
 */
async function bulkPurgeAll(customerNumber, accountNumber) {
  let snResult = snDb
    .allDocs({ include_docs: true })
    .then((allDocs) => {
      return allDocs.rows.map((row) => {
        return { _id: row.id, _rev: row.doc._rev, _deleted: true }
      })
    })
    .then((deleteDocs) => {
      return snDb
        .bulkDocs(deleteDocs)
        .then((res) => {
          console.log('purged all snDb now compacting')
          return snDb.compact()
          // successfully deleted now insert the new list of callMonth
        })
        .catch((err) => {
          console.error(err)
        })
    })
    .then((res) => {
      return snUsageDb.allDocs({ include_docs: true }).then((allDocs) => {
        return allDocs.rows.map((row) => {
          return { _id: row.id, _rev: row.doc._rev, _deleted: true }
        })
      })
    })
    .then((deleteSnUsage) => {
      return snUsageDb
        .bulkDocs(deleteSnUsage)
        .then((res) => {
          // console.log(res);
          console.log('purged all snUsageDb now compacting')
          return snUsageDb.compact()
          // successfully deleted now insert the new list of callMonth
        })
        .catch((err) => {
          console.error(err)
        })
    })
    .then((res) => {
      return snUsageMonthDb.allDocs({ include_docs: true }).then((allDocs) => {
        return allDocs.rows.map((row) => {
          return { _id: row.id, _rev: row.doc._rev, _deleted: true }
        })
      })
    })
    .then((deleteSnUsage) => {
      return snUsageMonthDb
        .bulkDocs(deleteSnUsage)
        .then((res) => {
          // console.log(res);
          console.log('purged all snUsageDb now compacting')
          return snUsageMonthDb.compact()
          // successfully deleted now insert the new list of callMonth
        })
        .catch((err) => {
          console.error(err)
        })
    })
    .then((res) => {
      return equipmentSummaryDb.allDocs({ include_docs: true }).then((allDocs) => {
        return allDocs.rows.map((row) => {
          return { _id: row.id, _rev: row.doc._rev, _deleted: true }
        })
      })
    })
    .then((equipmentSummary) => {
      return equipmentSummaryDb
        .bulkDocs(equipmentSummary)
        .then((res) => {
          console.log('purged all equipmentSummary now compacting')
          return equipmentSummaryDb.compact()
        })
        .catch((err) => {
          console.error(err)
        })
    })
    .then(() => {
      return deviceInContractDb.allDocs({ include_docs: true }).then((allDocs) => {
        return allDocs.rows.map((row) => {
          return { _id: row.id, _rev: row.doc._rev, _deleted: true }
        })
      })
    })
    .then((deviceInContract) => {
      return deviceInContract
        .bulkDocs(deviceInContract)
        .then((res) => {
          console.log('purged all deviceInContract now compacting')
          return deviceInContract.compact()
        })
        .catch((err) => {
          console.error(err)
        })
    })
    .then((res) => {
      return snCustomerInfoDb.allDocs({ include_docs: true }).then((allDocs) => {
        return allDocs.rows.map((row) => {
          return { _id: row.id, _rev: row.doc._rev, _deleted: true }
        })
      })
    })
    .then((deleteCustomerInfo) => {
      return snCustomerInfoDb
        .bulkDocs(deleteCustomerInfo)
        .then((res) => {
          console.log('purged all snCustomerInfoDb ')
          return res
        })
        .catch((err) => {
          console.error(err)
        })
    })

  return Promise.all([snResult])
}

/**
 * Funtiona to return all SN...
 * @returns {Promise<void>}
 */
async function findAllSnForIndexing() {
  let allOptions = { include_docs: true }
  return snDb.allDocs(allOptions).then((result) => {
    let snNumbers = []
    if (result.total_rows > 0) {
      result.rows.forEach(function (snNumberRow) {
        snNumbers.push(snNumberRow.doc)
      })
    }
    return snNumbers
  })
}

async function createIndex() {
  console.log('createIndex started')
  return 'done'
}

// Check if all the database has records...
async function databaseValid() {
  let billMonthExists = snUsageMonthDb.allDocs({ include_docs: false, limit: 1 }) // last row is some dubious index
  let customerExists = snCustomerInfoDb.allDocs({ include_docs: true, key: 'customerInfo', limit: 1 })
  return Promise.all([billMonthExists, customerExists]).then(([billMonthExists, customerExists]) => {
    let valid = true
    if (billMonthExists.rows.length === 0) {
      console.log('databaseValid snUsageMonthDb has no rows')
      valid = false
    }
    // check customer version with configuration properties
    if (customerExists.rows.length === 0) {
      console.log('databaseValid customerExists has no rows')
      valid = false
    } else {
      const versionNumber = ConfigProperties.getVersion()
      const storedVersion = customerExists.rows[0].doc.appVersion
      console.log('stored version is ' + storedVersion + ' new version number is ' + versionNumber)
      if (storedVersion !== versionNumber) {
        valid = false
      }
    }
    const blitzLink = customerExists.rows[0].doc.blitzLink
    valid = FetchReportHelper.checkExpiredLink(blitzLink).then((result) => {
      if (result.isExpired) {
        deleteDatabase()
      }
      return !result.isExpired
    })

    return valid
  })
}

async function getSnUsageDb() {
  return getCurrentGlobalFilter().then((globalFilter) => {
    if (globalFilter && globalFilter.value && globalFilter.value !== 'ALL') {
      console.log('getSnUsageDb global filter is set for ' + globalFilter.value + ' using accountSnUsageDb')
      return acctSnUsageDb
    } else {
      // console.log('getSnUsageDb no filter set returning snUsageDb')
      return snUsageDb
    }
  })
}

function getSnDb() {
  return getCurrentGlobalFilter().then((globalFilter) => {
    if (globalFilter && globalFilter.value && globalFilter.value !== 'ALL') {
      // console.log('getSnDb global filter is set for ' + globalFilter.value + ' using acctSnDb')
      return acctSnDb
    } else {
      // console.log('getSnDb no filter set returning snUsageDb')
      return snDb
    }
  })
}

function getSnCostCentreDb() {
  return getCurrentGlobalFilter().then((globalFilter) => {
    if (globalFilter && globalFilter.value && globalFilter.value !== 'ALL') {
      // console.log('getSnCostCentreDb global filter is set for ' + globalFilter.value + ' using acctSnCostCentreDb')
      return acctSnCostCentreDb
    } else {
      // console.log('getSnDb no filter set returning snUsageDb')
      return snCostCentreDb
    }
  })
}

function getSnUsageMonthDb() {
  return getCurrentGlobalFilter().then((globalFilter) => {
    if (globalFilter && globalFilter.value && globalFilter.value !== 'ALL') {
      // console.log('getSnUsageMonthDb global filter is set for ' + globalFilter.value + ' using acctSnUsageMonthDb')
      return acctSnUsageMonthDb
    } else {
      // console.log('getSnUsageMonthDb no filter set returning snUsageMonthDb')
      return snUsageMonthDb
    }
  })
}

function getEquipmentSummaryDb() {
  return getCurrentGlobalFilter().then((globalFilter) => {
    if (globalFilter && globalFilter.value && globalFilter.value !== 'ALL') {
      // console.log('getEquipmentSummaryDb global filter is set for ' + globalFilter.value + ' using acctEquipmentSummaryDb')
      return acctEquipmentSummaryDb
    } else {
      // console.log('getEquipmentSummaryDb no filter set returning equipmentSummaryDb')
      return equipmentSummaryDb
    }
  })
}

function getDeviceInContractDb () {
    return deviceInContractDb
}

function getSnCustomerInfoDb() {
  return snCustomerInfoDb
}

function getBuybackDb() {
  return buybackDb
}

function getLocalUserList() {
  // const userList = config.users
  // console.log('users ', userList)
  return []
}

// export {bulkInsertSn};
export { bulkInsertSnUsage, bulkInsertSnMonthUsage, bulkInsertEquipmentSummary }
export { bulkPurgeAll, databaseValid, getCurrentGlobalFilter }
export { createIndex, getCustomerInfo, insertCustomerInfo }
export { getSnDb, getSnCostCentreDb, getSnUsageDb, getSnUsageMonthDb, getEquipmentSummaryDb, getSnCustomerInfoDb, getBuybackDb, getDeviceInContractDb }
export { insertDashboardInfo, insertTopTenInfo, bulkInsertDeviceInContract }
export { bulkInsertSn, applyGlobalFilter, findAllSnForIndexing, deleteDatabase, deleteBuybackDatabase, bulkInsertBuyback }
export { getLocalUserList, globalFilterALL }
