import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/functions'
// import apps from "firebase.apps";

// fetch('/__/firebase/init.json').then(async response => {
//   Firebase.initializeApp(await response.json())
// })
// export default firebase

const region = 'australia-southeast1'

if (process.env.NODE_ENV === 'development') {
  const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
  }
  !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : ''
  // Firebase.initializeApp(firebaseConfig)
  console.log('firebase setting to use localhost emulator')
  firebase.auth().useEmulator('http://localhost:9099')
  firebase.firestore().useEmulator('localhost', 8080) // this is not validated. Expect to fail.
  // firebase.functions().useEmulator('localhost', 5001) // this is not validated. Expect to fail.
  firebase.app().functions(region).useEmulator('localhost', 5001)
  // FirebaseFunctions.useEmulator('localhost', 5001)
} else {
  //prod environment
  if (!firebase.apps.length) {
    fetch('/__/firebase/init.json').then(async (response) => {
      firebase.initializeApp(await response.json())
    })
  }
}

console.log('runtime environment is ' + process.env.NODE_ENV)

export const loginWithToken = async (signInToken) => {
  console.log('loginUser called with ' + signInToken)
  return firebase.auth().signInWithCustomToken(signInToken)
}

export const getDownloadUrl = async (accessKey) => {
  const generateDownloadUrl = firebase.app().functions(region).httpsCallable('generateDownloadUrl')
  // return {fileName: 'BLITZ 202104.xls', signedUrl: 'https://blah' }
  return generateDownloadUrl({ accessKey: accessKey }).then((result) => {
    console.log('getDownloadUrl URL result is ', result.data)
    return result.data
  })
}
