
export default {
    // Here we will create a getter
  refreshRequired: state => state.refreshRequired,
  domesticDataTable: state => state.domesticDataTable,
  domesticDataTableLabels: state => state.domesticDataTableLabels,
  domesticDataCategory: state => state.domesticDataCategory,
  domesticVoiceCategory: state => state.domesticVoiceCategory,
  overseasDataCategory: state => state.overseasDataCategory,
  noUsageCategory: state => state.noUsageCategory,
  selectedTab: state => state.selectedTab,
  snFilter: state => state.snFilter,
  defaultTopTenMonth: state => state.defaultTopTenMonth,
}
