
export default {
    commitProgress (state, progress) {
        console.log('commit progress is ' + progress)
        state.isShowProgress = progress
    },
    commitContainsData (state, containsData) {
        state.containsData = containsData
    },
    commitRowsRead (state, count) {
        state.rowsRead = count
    },
    commitAccountSummaryChart (state, data) {
        state.accountSummaryChart = data
    },
    commitDomesticDataScatterChart (state, data) {
        state.snScatterChart = data
    },
    commitDomesticDataScatterDatePreset (state, data) {
        state.snScatterDomesticDataDatePreset = data
    },
}
