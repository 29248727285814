import moment from 'moment'
import * as pizzipUtils from 'pizzip/utils'

const mtPattern = /^((JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\s((20)\d{2})\s([A-Z ]+)\s(CALLS|MINUTES OF USE|USAGE \(MB\)|EXCESS USAGE \(\$\)|SMS)$)/ // USAGE (MB) or EXCESS USAGE ($)

const billPattern = /^((BILL TOTAL)\s(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\s((20)\d{2})$)/ // BILL TOTAL AUG 2017

const groupPattern = /(([A-Z\-]+)\-((20)\d{2})\-(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)$)/

const monthHash = {
  JAN: '01',
  FEB: '02',
  MAR: '03',
  APR: '04',
  MAY: '05',
  JUN: '06',
  JUL: '07',
  AUG: '08',
  SEP: '09',
  OCT: '10',
  NOV: '11',
  DEC: '12',
}

const Utils = {
  /**
   * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
   *
   * @param {Int} num The number of bytes.
   */
  convertToYearMonth (year, monthLabel) {
    const yearMonth = parseInt(year + monthHash[monthLabel], 10) // eg 201807
    return yearMonth
  },
  convertMinutesToDhms (minutes) {
  minutes = Math.round(minutes)
  // let d = Math.floor(seconds / (3600 * 24))
  // let h = Math.floor(seconds % (3600 * 24) / 3600)
  // let m = Math.floor(seconds % 3600 / 60)
  // let s = Math.floor(seconds % 60)
    let d = Math.floor(minutes / (60 * 24))
    let h = Math.floor(minutes % (60 * 24) / 60)
    let m = Math.floor(minutes % 60)

  // let dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : ''
  // let hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : ''
  // let mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes') : ''
  let dDisplay = d > 0 ? d + 'd ' : ''
  let hDisplay = h > 0 ? h + 'h ' : ''
  let mDisplay = m > 0 ? m + 'm ' : ''
  return dDisplay + hDisplay + mDisplay
},

  /**
   * Compare and return the variance percentage round down and variance indicator
   * @param numberValue
   * @param average3Months
   * @returns {percent: 23, indicator: 'up' or 'down' or 'no change'}
   */
  calculateVariance (numberValue, average3Months) {
    // defensive coding when avg3 is 0. - stop divide by 0 error
    // console.log('calculateVariance ' + numberValue + ' avg3 months ' + average3Months)
    if (numberValue > 0.0 && average3Months === 0.0) {
      return { percent: '100%', indicator: 'up' }
    }
    if (numberValue === 0.0 && average3Months > 0.0) {
      return { percent: '100%', indicator: 'down' }
    }
    if (numberValue === average3Months) {
      return { percent: '0%', indicator: 'no-change' }
    }
    // console.log('calculateVariance number ' + numberValue + ' avg3Months ' + average3Months)
    const percent = Math.floor(((numberValue - average3Months) / average3Months) * 100)
    return { percent: percent + '%', indicator: percent > 0 ? 'up' : 'down' }
  },

  formatNumber (numberValue) {
    if (numberValue) {
      return new Intl.NumberFormat('en-US',
      ).format(numberValue) // '$100.00'
    } else {
      return new Intl.NumberFormat('en-US',
      ).format(0) // '$100.00'
    }
  },

  formatCurrency (money) {
    if (money) {
      return new Intl.NumberFormat('en-US',
        { style: 'currency', currency: 'USD' }
      ).format(money) // '$100.00'
    } else {
      return new Intl.NumberFormat('en-US',
        { style: 'currency', currency: 'USD' }
      ).format(0.00) // '$100.00'
    }
  },
  formatDate (date) {
    if (date && date !== '') {
      return moment(date).format('DD MMM YYYY')
    } else {
      return ''
    }
  },
  readableBytes (bytes) {
    if (bytes === 0) {
      return '0 Mb'
    }
    if (bytes < 0.6) {
      return '0 Mb'
    }
    let i = Math.floor(Math.log(bytes) / Math.log(1024))
      // sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let sizes = ['Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb']
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i]
  },
  /**
   * convert an array from 201706 to Jul
   * @param yearMonthArray
   */
  convertToMonthArray (yearMonthArray) {
    let months = []
    yearMonthArray.forEach(yearMonth => {
      const month = moment(yearMonth + '01', 'YYYYMMDD')
      months.push(month.format('MMM'))
    })
    return months
  },
  convertToTimestampArray (yearMonthArray) {
    let months = []
    yearMonthArray.forEach(yearMonth => {
      const month = moment(yearMonth + '01', 'YYYYMMDD')
      months.push(month)
    })
    return months
  },

  async loadFile (url, callback) {
    pizzipUtils.getBinaryContent(url, callback)
  },
  base64DataURLToArrayBuffer (dataURL) {
    const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/
    if (!base64Regex.test(dataURL)) {
      return false
    }
    const stringBase64 = dataURL.replace(base64Regex, '')
    let binaryString
    if (typeof window !== 'undefined') {
      binaryString = window.atob(stringBase64)
    } else {
      binaryString = Buffer.from(stringBase64, 'base64').toString('binary')
    }
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      const ascii = binaryString.charCodeAt(i)
      bytes[i] = ascii
    }
    return bytes.buffer
  },
  /**
   *
   * @param deviceSummaryList
   * @param numberOfTopItem
   * @param totalBill
   * @param totalActivity
   * @param totalDevices
   * @returns {unknown[]}
   */
  reduceEquipmentList (deviceSummaryList, deviceCategory, numberOfTopItem, totalBill, totalActivity, totalDevices) {
    const mergedArray = Array.from(
      deviceSummaryList.reduce( // we want the make and not device!!
        (entryMap, device) => {
          let a = entryMap.get(device[deviceCategory]) // device.make
          if (a) { // device exists in map
            a.billTotal = a.billTotal + device.billTotal
            a.active = a.active + device.active
            a.qty = a.qty + device.qty
          } else {
            entryMap.set(device[deviceCategory], { category: deviceCategory, deviceName: device[deviceCategory], billTotal: device.billTotal, active: device.active, qty: device.qty }) // device.make
          }
          return entryMap
        }
        , new Map(),
      ).values(),
    )
    mergedArray.sort((a, b) => b.qty - a.qty) // sort by desc qty
    // split to top 4 and others...
    let top5List = mergedArray.slice(0, numberOfTopItem)
    const others = mergedArray.slice(numberOfTopItem) // swe if this bombs out!! ie index does not exist
    if (others.length > 0) {
      let otherDevice = {
        deviceName: 'Others',
        billTotal: 0.0,
        active: 0,
        qty: 0,
      }
      others.forEach(other => {
        otherDevice.qty += other.qty
        otherDevice.billTotal += other.billTotal
        otherDevice.active += other.active
      })
      top5List.push(otherDevice)
    }
    top5List.forEach(top5 => {
      top5.bill = top5.billTotal
      top5.activity = top5.active
      top5.devices = top5.qty
      top5.billTotal = totalBill
      top5.activityTotal = totalActivity
      top5.devicesTotal = totalDevices
    })
    return top5List
  },

  /**
   * matchAllColumnValues the columnList values must all match
   * @param columnsList a list of {name: 'make' value: 'Apple'}
   * @param row
   * @returns Boolean
   */
  matchAllColumnValues (columnsList, row) {
    let match = true
    columnsList.forEach(column => {
     if (row[column.name] !== column.value) {
      match = false
    }
    })
    return true
  }
}
export default Utils
