import { findAllConfiguredOption } from '@/api/ConfiguredOptionReport'

export default {
  // Here we will create action to populate
  async setDownloading ({ commit }, value) {
    commit('setDownloading', value)
  },
  async setByteDownloaded ({ commit }, value) {
    commit('setByteDownloaded', value)
  },
  async setTotalByte ({ commit }, value) {
    commit('setTotalByte', value)
  },
}
