export default {
  refreshRequired: false,
  topTenMake: [],
  topTenModel: [],
  topTenType: [],
  equipmentTableFilter: {}, // filter applied to equipment summary table
  equipmentChartFilter: { key: null, deviceType: null, make: null, deviceName: null },
  snTableFilter: {}, // filter applied to service number table
  snFilter: {}
}
