import { set, toggle } from '@/utils/vuex'

export default {
  setCurrentSn: set('currentSn'),
  setRefreshRequired: set('refreshRequired'),
  setDomesticVoiceAverage12Months: set('domesticVoiceAverage12Months'),
  setDomesticVoiceTotal12Months: set('domesticVoiceTotal12Months'),
  setDomesticDataAverage12Months: set('domesticDataAverage12Months'),
  setDomesticDataTotal12Months: set('domesticDataTotal12Months'),

}
