
// import { resolveMaxMinYearMonth } from '@/api/Preprocessor'
import { insertDashboardInfo, insertTopTenInfo } from '@/api/PouchDao'
import { prepareDashboard, prepareTopTenTable, getTopTenInfo, getDashboardInfo } from '@/api/PouchDaoReport'
import { getFlexiPlanList } from '@/api/PlanDaoReport'
import moment from 'moment'
import Utils from '@/api/util'
import store from '@/store'

export default {
  // Here we will create action to populate
  async refreshRequired ({ commit, dispatch }, value) {
    console.log('refreshRequired setting to ' + value)
    commit('setRefreshRequired', value)
    // dispatch('loadData')
    // dispatch('loadTopTen')
  },
  async loadTopTen ({ commit, dispatch }) {
    commit('setTopTenLoading', true)
    prepareTopTenTable().then((result) => {
      console.log('loadTopTen completed')
      // console.log(result[1])
      const topTens = result[0]
      const topTensVoice = result[1]
      const topTensOverseas = result[2]

      const topTenInfo = {
        // domesticUsageTop10: topTens.domesticUsageTop10.map(usage => { return { sn: usage.sn, qty: Utils.readableBytes(usage.qty), userName: usage.userName } }),
        domesticUsageTop10: topTens.domesticUsageTop10,
        // domesticDurationTop10: topTens.domesticDurationTop10,
        domesticCallTop10: topTens.domesticCallTop10,
        domesticBillTotalTop10: topTens.domesticBillTotalTop10,
        overseasCallTop10: topTensOverseas.overseasCallTop10,
        domesticVoiceCallTop10: topTensVoice.domesticVoiceCallTop10,
        domesticVoiceDurationTop10: topTensVoice.domesticVoiceDurationTop10,
      }
      insertTopTenInfo(topTenInfo).then((result) => {
        dispatch('updateTopTenInfo', topTenInfo)
      })
    })
  },
  async loadData ({ commit, dispatch }) {
    console.log('dashboard loadData started calling prepareDashboard')
    prepareDashboard().then((
      [[latestMonth
        , domesticUsageTotal
        , overseasUsageTotal
        , domesticSmsTotal
        , domesticVoiceTotal // activeCallCount,
        , currentMonthBillTotal
        , previousMonthBillTotal
        , billTotalChartPromise
        , domesticVoice12MonthPromise
        , domesticData12MonthPromise
        , nationalCallSparkLinePromise
        , nationalDataSparkLinePromise
        , internationalDataSparkLinePromise
        , dataAllowanceTotal
      ],
        mobileCountPromise,
        findAllAccountPromise,
        findAllCidnPromise,
        findADeviceListPromise,
      ]) => {
      // console.log('prepareDashboard completed latest month is ' + latestMonth)
      // const currentMonth = moment(latestMonth + '01', 'YYYYMMDD')
      console.log('prepareDashboard currentMonth ' + latestMonth + ' bill total ' + currentMonthBillTotal.billTotal + ' avg3Months ' + currentMonthBillTotal.avg3Months) // eg JUN 2019
      const currentMonthIncrease = (((currentMonthBillTotal.billTotal - previousMonthBillTotal.billTotal) / currentMonthBillTotal.billTotal) * 100).toFixed(3)
      console.log('prepareDashboard percentage is ' + currentMonthIncrease)

      const currentMonthInfo = {
        currentMonth: latestMonth,
        currentMonthLabel: moment(latestMonth + '01', 'YYYYMMDD').format('MMM YYYY'),
        currentMonthBillTotal: currentMonthBillTotal.billTotal,
        currentMonthAvg3Months: currentMonthBillTotal.avg3Months,
        currentMonthIncrease: currentMonthIncrease,
        currentMonthActive: mobileCountPromise.currentMonthActive,
        mobileCount: mobileCountPromise.mobileCount, // total mobiles
      }

      console.log('domesticUsageTotal is ', domesticUsageTotal)

      const domesticDataInfo = {
        domesticDataCount: domesticUsageTotal.usageCount,
        domesticDataSum: domesticUsageTotal.usageSum,
        nationalDataSparkLineData: nationalDataSparkLinePromise.callCountList,
        domesticDataAvg3Months: domesticUsageTotal.usageAvg3Months,
        domesticDataUsageLabel: Utils.readableBytes(domesticUsageTotal.usageSum),
        domesticDataExcessSum: domesticUsageTotal.excessSum,
      }

      console.log('domesticDataInfo is ', domesticDataInfo)

      const overseasDataInfo = {
        overseasDataCount: overseasUsageTotal.usageCount,
        overseasDataSum: overseasUsageTotal.usageSum, // in Mb
        overseasDataAvg3Months: overseasUsageTotal.usageAvg3Months,
        overseasDataUsageLabel: Utils.readableBytes(overseasUsageTotal.usageSum),
        overseasDataExcessSum: overseasUsageTotal.excessSum,
        overseasDataSparkLineData: internationalDataSparkLinePromise.callCountList,
      }

      console.log('overseasDataSparkLineData is ', overseasDataInfo.overseasDataSparkLineData)
      console.log('prepareDashboard mobile count ' + mobileCountPromise.mobileCount + ' activeCallCount ' + domesticVoiceTotal.callCount + ' activeCallSum ' + domesticVoiceTotal.callTotal) // eg JUN 2019

      const domesticVoiceInfo = {
        smsTotal: domesticSmsTotal.callTotal,
        smsAvg3Months: domesticSmsTotal.callAvg3Months,
        callTotal: domesticVoiceTotal.callTotal,
        callCount: domesticVoiceTotal.callCount,
        callAvg3Months: domesticVoiceTotal.callTotalAvg3Months,
        durationTotal: domesticVoiceTotal.durationTotal,
        durationAvg3Months: domesticVoiceTotal.durationAvg3Months,
        nationalCallSparkLineData: nationalCallSparkLinePromise.callCountList, // refer to PouchDaoReport find12MonthsNationalCallSparkLine
      }
      console.log('prepareDashboard account count ' + findAllAccountPromise.acctCount + ' cidn ' + findAllCidnPromise.cidnCount) // eg JUN 2019

      const accountInfo = {
        cidnCount: findAllCidnPromise.cidnCount,
        acctCount: findAllAccountPromise.acctCount,
      }

      const deviceSummaryList = {
        list: findADeviceListPromise.deviceList,
      }

      const dashboardInfo = {
        currentMonthInfo: currentMonthInfo,
        domesticDataInfo: domesticDataInfo,
        overseasDataInfo: overseasDataInfo,
        domesticVoiceInfo: domesticVoiceInfo,
        accountInfo: accountInfo,
        domesticVoice12Months: domesticVoice12MonthPromise.callCountList, // ** need to convert yearMonth from moment to string...
        domesticData12Months: domesticData12MonthPromise.callCountList,
        // nationalCallSparkLineData: nationalCallSparkLinePromise.callCountList, //refer to PouchDaoReport find12MonthsNationalCallSparkLine
        // nationalDataSparkLineData: nationalDataSparkLinePromise.callCountList,
        internationalDataSparkLineData: internationalDataSparkLinePromise.callCountList,
        dataAllowanceTotal: dataAllowanceTotal.allowanceTotal,

        billTotal12Months: billTotalChartPromise.billList, // ** need to convert yearMonth from moment to string...
        deviceSummaryList: deviceSummaryList,
      }
      console.log('prepareDashboard calling updateDashboardInfo to unpack dashboardInfo into cache')
      insertDashboardInfo(dashboardInfo).then((result) => {
        dispatch('updateDashboardInfo', dashboardInfo)
      })
    })
  },
  async refreshDashboardCache ({ commit, dispatch }) {
    console.log('refreshDashboardCache started')
    getDashboardInfo().then((dashboardInfo) => {
      console.log('refreshDashboardCache ended')
      dispatch('updateDashboardInfo', dashboardInfo)
    })
  },
  async refreshTopTenCache ({ commit, dispatch }) {
    console.log('refreshTopTenCache started')
    getTopTenInfo().then((topTenInfo) => {
      console.log('refreshTopTenCache ended')
      dispatch('updateTopTenInfo', topTenInfo)
    })
  },
  async updateTopTenInfo ({ commit }, topTenInfo) {
    console.log('updateTopTenInfo started')
    commit('setTopTenOverseasCall', topTenInfo.overseasCallTop10)
    commit('setTopTenDomesticCall', topTenInfo.domesticCallTop10)
    commit('setTopTenDomesticBillTotal', topTenInfo.domesticBillTotalTop10)
    commit('setTopTenDomesticUsage', topTenInfo.domesticUsageTop10)
    commit('setTopTenDomesticVoiceCall', topTenInfo.domesticVoiceCallTop10)
    commit('setTopTenDomesticVoiceDuration', topTenInfo.domesticVoiceDurationTop10)
    commit('setTopTenLoading', false)
    console.log('updateTopTenInfo ended')
  },
  async updateTopTenDomesticVoiceInfo ({ commit }, topTenInfo) {
    console.log('updateTopTenDomesticVoiceInfo started')
    commit('setTopTenDomesticVoiceCall', topTenInfo.domesticVoiceTop10)
    commit('setTopTenLoading', false)
    console.log('updateTopTenDomesticVoiceInfo ended')
  },
  async updateDashboardInfo ({ commit, dispatch }, dashboardInfo) {
    console.log('updateDashboardInfo started')
    commit('setCurrentMonthLabel', dashboardInfo.currentMonthInfo.currentMonthLabel)
    commit('setCurrentMonthTotal', dashboardInfo.currentMonthInfo.currentMonthBillTotal)
    commit('setCurrentMonthIncrease', dashboardInfo.currentMonthInfo.currentMonthIncrease)
    commit('setCurrentMonthActive', dashboardInfo.currentMonthInfo.currentMonthActive)
    commit('setCurrentMonthAvg3Months', dashboardInfo.currentMonthInfo.currentMonthAvg3Months)
    commit('setMobileCount', dashboardInfo.currentMonthInfo.mobileCount)
    commit('setCurrentOverseasData', dashboardInfo.overseasDataInfo)
    commit('setCurrentDomesticData', dashboardInfo.domesticDataInfo)
    commit('setCurrentDomesticVoice', dashboardInfo.domesticVoiceInfo)
    commit('setCidnCount', dashboardInfo.accountInfo.cidnCount)
    commit('setAcctCount', dashboardInfo.accountInfo.acctCount)
    commit('setDeviceSummaryList', dashboardInfo.deviceSummaryList.list)
    commit('setDataAllowance', dashboardInfo.dataAllowanceTotal)
    if (dashboardInfo.domesticVoice12Months) {
      await dispatch('updateVoice12MonthChart', dashboardInfo.domesticVoice12Months)
    }
    if (dashboardInfo.domesticData12Months) {
      await dispatch('updateData12MonthChart', dashboardInfo.domesticData12Months)
    }
    if (dashboardInfo.billTotal12Months) {
      await dispatch('updateBillTotal12MonthChart', dashboardInfo.billTotal12Months)
    }
    if (dashboardInfo.deviceSummaryList.list) {
      await dispatch('loadEquipmentSummary')
    }
    await dispatch('loadPlanSummary')
    console.log('updateDashboardInfo ended')
    store.dispatch('app/setProgress', false)
    console.log('updateDashboardInfo setting refreshRequired to false')
    commit('setRefreshRequired', false)
  },
  async updateData12MonthChart ({ commit }, callCountList) {
    /**
     * prepare double chart domestic data usage MB and excess. duration not applicable
     *
     * next try naming the series
     *
     * @type {Array}
     */
    console.log('updateData12MonthChart started ')
    const domesticDatalabels = []
    const domesticDataseries1 = []
    const domesticDataseries2 = []
    const domesticSeries = [{ name: 'call', data: [] }, { name: 'duration', data: [] }]
    callCountList.forEach((callCount) => {
      const currentMonth = moment(callCount.yearMonth + '01', 'YYYYMMDD')
      // domesticDataseries1.push({ x: currentMonth.toDate(), y: Math.trunc(callCount.usage) })
      // domesticDataseries2.push({ x: currentMonth.toDate(), y: Math.trunc(callCount.excess) })
      domesticDataseries1.push({ x: callCount.yearMonth, y: Math.trunc(callCount.usage) })
      domesticDataseries2.push({ x: callCount.yearMonth, y: Math.trunc(callCount.excess) })
    })
    domesticSeries[0].data = domesticDataseries1 // usage
    domesticSeries[1].data = domesticDataseries2 // excess

    const domesticData12Months = {
      labels: domesticDatalabels,
      series: domesticSeries,
    }
    commit('setDomesticDataTotal12Months',
      domesticData12Months,
    )
  },
  /**
   *
   * @param commit
   * @param callCountList a list of [ {yearMonth: '201801', call: 102, duration: 12020}, {yearMonth: '201801', call: 102, duration: 12020}]
   * @returns {Promise<void>}
   */
  async updateVoice12MonthChart ({ commit }, callCountList) {
    /**
     * prepare double chart domestic voice
     *
     * next try naming the series
     *
     * @type {Array}
     */
    const domesticVoicelabels = []
    const domesticVoiceseries1 = []
    const domesticVoiceseries2 = []
    const domesticSeries = [{ name: 'call', data: [] }, { name: 'duration', data: [] }]
    callCountList.forEach((callCount) => {
      // const currentMonth = moment(callCount.yearMonth + '01 +0000', 'YYYYMMDD Z')
      // domesticVoiceseries1.push({ x: currentMonth.toDate(), y: Math.trunc(callCount.call) })
      // domesticVoiceseries2.push({ x: currentMonth.toDate(), y: Math.trunc(callCount.duration) })
      domesticVoiceseries1.push({ x: callCount.yearMonth, y: Math.trunc(callCount.call) })
      domesticVoiceseries2.push({ x: callCount.yearMonth, y: Math.trunc(callCount.duration) })
    })
    domesticSeries[0].data = domesticVoiceseries1
    domesticSeries[1].data = domesticVoiceseries2

    const domesticVoice12Months = {
      labels: domesticVoicelabels,
      series: domesticSeries,
    }
    commit('setDomesticVoiceTotal12Months',
      {
 labels: domesticVoice12Months.labels,
        series: domesticVoice12Months.series,
      })
  },
  async updateBillTotal12MonthChart ({ commit }, billList) {
    const labels = []
    const series = []
    console.log('updateBillTotal12MonthChart started')
    let maxMonth = '201501'
    billList.forEach(bill => {
      labels.push(bill.yearMonth)
      const currentYearMonthDay = moment(bill.yearMonth + '01 +0000', 'YYYYMMDD Z') // eg from 201712 to 20171201
      // const currentYearMonthDay = bill.yearMonth + '01' // eg from 201712 to 20171201
      // series.push({ x: currentYearMonthDay, y: Math.trunc(bill.total) })
      // series.push({ x: currentYearMonthDay.toDate(), y: Math.trunc(bill.total) })
      series.push({ x: bill.yearMonth, y: Math.trunc(bill.total) })
      maxMonth = bill.yearMonth
    })
    // const dummyYearMonthDay = moment(maxMonth + '01 +0000', 'YYYYMMDD Z').add(1,'months') // eg from 201712 to 20171201
    // console.log('updateBillTotal12MonthChart started maxMonth '+ maxMonth  + ' dummy ' + dummyYearMonthDay.toDate())
    // series.push({ x: dummyYearMonthDay.toDate(), y: 0 })
    const billTotal12Months = {
      series: [series],
      labels: labels,
    }
    commit('setBillTotal12Months',
      {
 labels: billTotal12Months.labels,
        series: billTotal12Months.series,
      })
    console.log('updateBillTotal12MonthChart ended')
  },
  async loadEquipmentSummary ({ commit, getters, dispatch }) {
    const totalBill = getters.deviceSummaryList.map(device => device.billTotal).reduce((prev, cur) => prev + cur, 0.0)
    // console.log('total bill ', totalBill)
    const totalActivity = getters.deviceSummaryList.map(device => device.active).reduce((prev, cur) => prev + cur, 0)
    // console.log('total activity ', totalActivity)
    const totalDevices = getters.deviceSummaryList.map(device => device.qty).reduce((prev, cur) => prev + cur, 0)
    const top8MakeList = Utils.reduceEquipmentList(getters.deviceSummaryList, 'make', 9, totalBill, totalActivity, totalDevices)
    // console.log('roll up top 5 + 1', top8MakeList)
    const top8ModelList = Utils.reduceEquipmentList(getters.deviceSummaryList, 'model', 9, totalBill, totalActivity, totalDevices)
    const top8TypeList = Utils.reduceEquipmentList(getters.deviceSummaryList, 'deviceType', 9, totalBill, totalActivity, totalDevices)
    commit('setEquipmentSummaryMake', top8MakeList)
    commit('setEquipmentSummaryModel', top8ModelList)
    commit('setEquipmentSummaryType', top8TypeList)
  },

  async loadPlanSummary ({ commit, getters, dispatch }) {

    let [ flexiPlanList, shareablePlanList, nonShareablePlanList ] = await getFlexiPlanList()

    commit('setFlexiPlanSummaryList', flexiPlanList )
    commit('setShareablePlanSummaryList', shareablePlanList )
    commit('setNonShareablePlanSummaryList', nonShareablePlanList )

  },
}
