import moment from 'moment'
import { isEmpty } from 'lodash'
import {
  getCustomerInfo,
  getSnDb,
} from './PouchDao'

let configuredOptions = [
  { name: 'internationalRoaming', label: 'International Roaming' },
  // { name: 'spendLimitActive', label: 'Spend Limit Active' },
  { name: 'mmsAccess', label: 'MMS Access' },
  // { name: 'videoCallingAccess', label: 'Video Calling Access' },
  { name: 'barOutgoingVoice', label: 'Bar Outgoing voice' },
  { name: 'barOutgoingSMS', label: 'Bar Outgoing SMS' },
  { name: 'barIncomingSMS', label: 'Bar Incoming SMS' },
  { name: 'barPremiumSSMS', label: 'Bar Premium SSMS' },
  { name: 'telstraCorp', label: 'Telstra Corp' },
  { name: 'telstraXtranet', label: 'Telstra Xtranet' },
  // { name: 'telstraDataPack', label: 'Telstra Data Pack' },
  // { name: 'telstraPCPack', label: 'Telstra PC Pack' },
  // { name: 'telstraInternet', label: 'Telstra Internet' },
  // { name: 'telstraWap', label: 'Telstra WAP' },
  // { name: 'iphoneAPN', label: 'Iphone APN' },
  // { name: 'barNextGWAP', label: 'Bar NextG WAP' },
  // { name: 'barNextGMMS', label: 'Bar NextG MMS' },
  // { name: 'barNextGInternetAccess', label: 'Bar NextG Internet Access' },
  // { name: 'barVideoCalls', label: 'Bar Video Cals' },
  // { name: 'telstraWapGsm', label: 'Telstra WAP GSM' },
  // { name: 'mmsAccessGsm', label: 'MMS Access GSM' },
  // { name: 'telstraInternetGSM', label: 'Telstra Internet GSM' },
  // { name: 'barGsmWap', label: 'Bar GSM WAP' },
  // { name: 'bar2gMms', label: 'Bar 2G MMS' },
  // { name: 'barGsmMobileInternet', label: 'Bar GSM Mobile Internet' },
  // { name: 'liberateFlag', label: 'Liberate Flag' },
  { name: 'oneNumberFlag', label: 'One Number Flag' },
]

/**
 * call this to return data for population the hero graph for ConfiguredOption
 * @returns {Promise<{name: *, value: *}[]>}
 */
async function findAllConfiguredOption (arrayLike) {
  console.log('findAllConfiguredOption started')
  const custInfo = await getCustomerInfo()
  return getSnDb().then(snDb => {
    return snDb.allDocs({ // retrieve all rows
      include_docs: true,
    })
  }).then(result => {
    let intlRoaming = 0
    let spendLimitActive = 0
    let mmsAccess
    if ((custInfo.biltzFileVersion == '2.0') && !configuredOptions.some(item => item.name === 'barRoaming')){
      //Add more columns from version 2.0 file
      configuredOptions = [...configuredOptions, 
        { name: 'barRoaming', label: 'Bar Roaming' },
        { name: 'barIDD', label: 'Bar IDD' },
        { name: 'usernamePrintOnBill', label: 'Username Print on Bill' },
      ]
    }
    // let mapSum = new Map()
    // use reduce to init the map of all the configured options
    let mapSum = configuredOptions.reduce(function (map, configuredOption) {
      if (!map.has(configuredOption.name)) {
        return map.set(configuredOption.name, {...configuredOption, count: 0})
      }
      // map.get(configuredOptions).push(configuredOption);
      return map
    }, new Map())
    result.rows.map((row) => row.doc).forEach(serviceNumberRow => {
        configuredOptions.forEach(option => {
          if ((serviceNumberRow[option.name] === 'YES') || (serviceNumberRow[option.name] === 'Y')) {
            const configuredOption = mapSum.get(option.name)
            // console.log('option', configuredOption)
            // mapSum.set(option, mapSum.get(option) + 1)
            configuredOption.count += 1
          }
        })
    })
    return mapSum
  }).then(mapSum => {
    // convert map to array
    // let map = new Map().set('a', 1).set('b', 2),
    //   array = Array.from(map, ([name, value]) => ({ name, value }));
    // arr = [...myMap].map(([name, value]) => ({ name, value }));
    return Array.from(mapSum, ([name, value]) => ({ ...value }))
  })
}

/**
 *
 * @param configuredOptionsArray an array of configuredOptions string
 * @returns {Promise<void>}
 */
async function filterUsers (configuredOptionsArray) {
  return getSnDb().then(snDb => {
    return snDb.allDocs({ // retrieve all rows
      include_docs: true,
    })
  }).then(result => {
    let users = []
    result.rows.map((row) => row.doc).forEach(serviceNumberRow => {
      let match = true
      configuredOptionsArray.forEach(option => {
        if ((serviceNumberRow[option] !== 'YES') && (serviceNumberRow[option] !== 'Y')) {
          match = false
        }
      })
      if (match) {
        users.push(serviceNumberRow)
      }
    })
    return users
  })
}

export {
  configuredOptions,
  findAllConfiguredOption,
  filterUsers
}
