import { queryLatestMonth, findLast12MonthsDomesticVoiceSn, findLast12MonthsDomesticVoiceTotal } from '@/api/PouchDaoReport'

export default {
  // Here we will create action to populate
  async refreshRequired ({ commit }, value) {
    commit('setRefreshRequired', value)
  },
  async refreshCurrentSn ({ commit }, value) {
    commit('setCurrentSn', value)
  },
  async refreshDomesticVoiceSn ({ commit }, sn) {
    console.log('refreshDomesticVoiceSn started')
    return queryLatestMonth().then(result => {
      return findLast12MonthsDomesticVoiceSn(result.earliest, result.latest, sn)
    })
  },
  //
  // refresh the average for call count and duration aka minutes of use
  async refreshDomesticVoiceAverage ({ commit, getters }) {
    console.log('refreshDomesticVoiceAverage started refresh is ' + getters.refreshRequired)
    return queryLatestMonth().then(result => {
      const earliest = result.earliest
      const latest = result.latest
      console.log('refreshDomesticVoiceAverage latest ' + latest + ' earliest ' + earliest)
      return findLast12MonthsDomesticVoiceTotal(result.earliest, result.latest)
    }).then(result => {
      const callCountList = result.callCountList // this is a list of items
      let domesticVoiceAverage12Months = []
      // routine to calculate average
      callCountList.forEach(callCount => {
        const snCallCount = callCount.snCallCount
        const snDurationCount = callCount.snDurationCount
        const yearMonth = callCount.yearMonth
        const call = callCount.call
        const duration = callCount.duration
        // average , next iteration try standard deviation to measure volatility
        const averageCallCount = call / snCallCount
        const averageDuration = duration / snDurationCount
        const domesticVoiceAverage = {
          yearMonth: yearMonth,
          averageCallCount: Math.round(averageCallCount),
          averageDuration: Math.round(averageDuration),
        }
        domesticVoiceAverage12Months.push(domesticVoiceAverage)
      })
      return Promise.resolve(domesticVoiceAverage12Months)
    }).then(result => {
      console.log('refreshDomesticVoiceAverage completed')
      // console.log(result)
      // we need label and series etc to populate the chart..
      commit('setDomesticVoiceAverage12Months', result)
    })
  },

}
