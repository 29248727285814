export default {
  refreshRequired: false,
  overseasDataCategory: 'CALL',
  domesticDataCategory: 'CALL',
  domesticVoiceCategory: 'CALL',
  selectedTab: 'BILL',
  domesticDataTable: [],
  domesticDataTableLabels: [],
  snFilter: {},
  defaultTopTenMonth: null,

}
