
export default {
    // Here we will create a getter
  refreshRequired: state => state.refreshRequired,
  topTenMake: state => state.topTenMake,
  topTenModel: state => state.topTenModel,
  topTenType: state => state.topTenType,
  equipmentTableFilter: state => state.equipmentTableFilter,
  equipmentChartFilter: state => state.equipmentChartFilter,
  snTableFilter: state => state.snTableFilter,
  snFilter: state => state.snFilter,
}
