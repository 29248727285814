
export default {
  // Here we will create action to populate
  async saveCallMonthAndTotal ({ commit }, data) {
    console.log('report actions saveCallMonthAndTotal ')
    // this is where we need to do all the things needed to populate the dashboard...
    // 1 the get current month eg 201901
    // 2 get the total
    commit('setCallMonth', data.callMonth)
    commit('setBillTotalMonth', data.billTotalMonth)
  },

}
