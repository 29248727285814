export default {
  refreshRequired: false,
  currentMonthTotal: 0.0, //  total of HS
  currentMonthActive: 0,
  currentMonthLabel: 'Jan', //  total of HS
  currentMonthIncrease: 0.0, // compared to last month bill total
  currentMonthAvg3Months: 0.0,
  currentDomesticVoice: {}, // current domesticVoice object
  currentDomesticData: {}, // current domesticData object
  currentOverseasData: {}, // current overseasData
  mobileCount: 0,
  acctCount: 0,
  cidnCount: 0,
  topTenLoading: true, // default to loading top ten
  topTenOverseasCall: [],
  topTenDomesticCall: [], // domestic data count
  topTenDomesticVoiceCall: [], // domestic voice count
  topTenDomesticVoiceDuration: [], // domestic voice duration aka minutes of use
  topTenDomesticUsage: [],
  // topTenDomesticDuration: [],
  topTenDomesticBillTotal: [],
  numberOfAccounts: 5, // column h ie acct
  /**
   *  data: {
            labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            series: [
              [12, 17, 7, 17, 23, 18, 38],
            ],
          },
   */
  billTotal12Months: { labels: [], series: [] },
  domesticVoiceTotal12Months: { labels: [], series: [] },
  domesticDataTotal12Months: { labels: [], series: [] },
  dataUsage: 333,
  deviceSummaryList: [],
  equipmentSummaryMake: [],
  equipmentSummaryType: [],
  equipmentSummaryModel: [],
  flexiPlanSummaryList:[],
  shareablePlanSummaryList:[],
  nonShareablePlanSummaryList:[],
  dataAllowance:0,
}
