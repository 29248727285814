import { set, toggle } from '@/utils/vuex'

export default {
  setCurrentMonthTotal: set('currentMonthTotal'),
  setCurrentMonthLabel: set('currentMonthLabel'),
  setCurrentMonthIncrease: set('currentMonthIncrease'),
  setCurrentMonthAvg3Months: set('currentMonthAvg3Months'),
  setMobileCount: set('mobileCount'),
  setCurrentMonthActive: set('currentMonthActive'),
  setAcctCount: set('acctCount'),
  setCidnCount: set('cidnCount'),
  setBillTotal12Months: set('billTotal12Months'),
  setDeviceSummaryList: set('deviceSummaryList'),
  setCurrentDomesticVoice: set('currentDomesticVoice'),
  setCurrentDomesticData: set('currentDomesticData'),
  setCurrentOverseasData: set('currentOverseasData'),
  setRefreshRequired: set('refreshRequired'),
  setTopTenLoading: set('topTenLoading'),
  setTopTenOverseasCall: set('topTenOverseasCall'),
  setTopTenDomesticCall: set('topTenDomesticCall'),
  setTopTenDomesticUsage: set('topTenDomesticUsage'),
  // setTopTenDomesticDuration: set('topTenDomesticDuration'),
  setTopTenDomesticBillTotal: set('topTenDomesticBillTotal'),
  setTopTenDomesticVoiceCall: set('topTenDomesticVoiceCall'),
  setTopTenDomesticVoiceDuration: set('topTenDomesticVoiceDuration'),
  setDomesticVoiceTotal12Months: set('domesticVoiceTotal12Months'),
  setDomesticDataTotal12Months: set('domesticDataTotal12Months'),
  setEquipmentSummaryMake: set('equipmentSummaryMake'),
  setEquipmentSummaryType: set('equipmentSummaryType'),
  setEquipmentSummaryModel: set('equipmentSummaryModel'),
  setFlexiPlanSummaryList: set('flexiPlanSummaryList'),
  setShareablePlanSummaryList: set('shareablePlanSummaryList'),
  setNonShareablePlanSummaryList: set('nonShareablePlanSummaryList'),
  setDataAllowance: set('dataAllowance'),
}
