export default {
  // Here we will create action to populate
  async refreshRequired({ commit }, value) {
    commit('setRefreshRequired', value)
  },
  async updateTopTenMake({ commit }, value) {
    commit('setTopTenMake', value)
  },
  async updateTopTenModel({ commit }, value) {
    commit('setTopTenModel', value)
  },
  async updateTopTenType({ commit }, value) {
    commit('setTopTenType', value)
  },
  /**
   *
   * @param commit
   * @param categoryLabel eg { category: this.dataCategory, label: this.chartOptions.labels[opts.dataPointIndex] }
   * @returns {Promise<void>}
   */
  async updateEquipmentTableFilter({ commit, getters }, categoryLabel) {
    // called from EquipmentTonTenPieChart when user click on pie chart
    commit('setEquipmentTableFilter', categoryLabel)
    const equipmentChartFilter = { ...getters.equipmentChartFilter }
    equipmentChartFilter.key = categoryLabel.category
    if (categoryLabel.category === 'deviceType') {
      equipmentChartFilter.deviceType = categoryLabel.label
      console.log('dataType ' + categoryLabel.category, equipmentChartFilter)
    }
    if (categoryLabel.category === 'make') {
      equipmentChartFilter.make = categoryLabel.label
      console.log('make ' + categoryLabel.category, equipmentChartFilter)
    }
    if (categoryLabel.category === 'deviceName') {
      equipmentChartFilter.deviceName = categoryLabel.label
      console.log('dataName ' + categoryLabel.category, equipmentChartFilter)
    }
    if (categoryLabel.category === 'mroContractDeviceName') {
      equipmentChartFilter.mroContractDeviceName = categoryLabel.label
      console.log('mroContractDeviceName ' + categoryLabel.category, equipmentChartFilter)
    }
    commit('setEquipmentChartFilter', equipmentChartFilter)
  },
  async clearEquipmentChartFilter({ commit }) {
    const equipmentChartFilter = { key: null, deviceType: null, make: null, deviceName: null }
    commit('setEquipmentChartFilter', equipmentChartFilter)
  },
  async updateSnTableFilter({ commit }, value) {
    commit('setSnTableFilter', value)
  },
  /**
   * this is called when user clicks on the SN row in EquipmentDataTable.vue with the intention to view the SN detail page...
   * @param commit
   * @param value
   * @returns {Promise<void>}
   */
  async updateSnFilter({ commit }, value) {
    commit('setSnFilter', value)
  }
}
