import { findAllConfiguredOption } from '@/api/ConfiguredOptionReport'

export default {
  // Here we will create action to populate
  async refreshRequired ({ commit }, value) {
    commit('setRefreshRequired', value)
  },
  async updateHeroGraph ({ commit }, value) {
    commit('setHeroGraphData', value)
  },
  async loadHeroGraph ({ commit, dispatch }) {
    console.log('loadHeroGraph to account ')
    return findAllConfiguredOption().then(optionArray => {
      commit('setHeroGraphData', optionArray)
    })
  },
  async updateSnFilter ({ commit }, value) {
    commit('setSnFilter', value)
  },
}
