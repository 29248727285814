export default {
    isShowProgress: false,
    rowsRead: 0,
    containsData: false,
    accountSummaryChart: {
        series: [],
        labels: [],
    },
    snScatterDomesticDataDatePreset: {
        lastMonth: {
            start: 201111,
            end: 201112,
        },
        lastQuarter: {
            start: 201109,
            end: 201112,
        },
        lastYear: {
            start: 201012,
            end: 201112,
        },

    },
    snScatterChart: {
        series: [
            {
                name: 'local calls',
                data: [
                    { x: 100, y: 53 },
                    { x: 200, y: 60 },
                    { x: 250, y: 23 },
                    { x: 280, y: 83 },
                ],
            },
        ],
        labels: [],
    },
}
