<template>
  <router-view />
</template>

<script>

  import store from '@/store'
  import { databaseValid, resetAcctDatabase } from '@/api/PouchDao'
  import { getDashboardInfo } from '@/api/PouchDaoReport'

  import router from '@/router/index.js' // Just added this line and code works !!
  import ConfigProperties from '@/api/ConfigurationProperties'

  /**
     * refer to router to see which is the first page to display...
     */
  export default {
    name: 'App',
    mounted () {
      console.log('Blitz App.vue mounted now checking database if is is valid')
      /**
       * we need to reload existing data from db into cache if required
       * eg if customerInfo does not exists
       */
      let checkDatabase = databaseValid()
      checkDatabase.then(result => {
        console.log('app databaseValid result is ' + result)
        if (result) {
          this.loadData()
        } else {
          /**
           * check redirect after login is successful
           */
          console.log('customerInfo version is OLD need to force refresh to settings after login setting app setFirstTimeUse aka ')
          store.dispatch('app/setFirstTimeUse', true)
          // router.push({ name: 'Settings' }) // redirect page to Settings for loading data
        }
      })
    },
    methods: {
      loadData () {
        console.log('app loadData started')
        store.dispatch('app/loadData').then((customerInfo) => {
          console.log('app loadData from DB to cache completed')
          // refer to pouchDao insertCustomerInfo for the appVersion....
          if (customerInfo && customerInfo.appVersion === ConfigProperties.getVersion()) {
            store.dispatch('app/setProgress', true)
            getDashboardInfo().then(result => {
              if (result) {
                console.log('dashboard info exists calling refreshDashboardCache to refresh data into vuex from pouchdb')
                store.dispatch('dashboard/refreshDashboardCache') // retrieve from pouch and load into vuex
                store.dispatch('dashboard/refreshTopTenCache') // retrieve from pouch and load into vuex
                // store.dispatch('serviceNumber/refreshDomesticVoiceAverage')
              } else {
                console.log('dashboard info does not exist! setting refresh required for dashboard to perform refresh')
                store.dispatch('dashboard/refreshRequired', true)
              }
            })
            // testing to reset account databases
            // resetAcctDatabase(3416698904).then(result => {
            //   console.log('acct database reset ')
            // })
          } else {
            console.log('customerInfo version is OLD need to force refresh')
          }
        })
      },
    },
  }
</script>
