
export default {
    // Here we will create a getter
    isShowProgress: state => state.isShowProgress,
    rowsRead: state => state.rowsRead,
    accountSummaryChart: state => state.accountSummaryChart,
    snScatterChart: state => state.snScatterChart,
    snScatterDomesticDataDatePreset: state => state.snScatterDomesticDataDatePreset,
    containsData: state => state.containsData
  ,

}
