
import FlexSearchSn from '@/api/FlexSearchSn'

/**
 * store result of interactions with snDb
 */
export default {
    namespaced: true,
    state: {
        rowCount: 0,
        loading: true,
        result: [
            {
            name: 'sn1',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: '1%',
            },
            {
                name: 'sn2',
                calories: 159,
                fat: 6.0,
                carbs: 24,
                protein: 4.0,
                iron: '1%',
            },
        ],
    },
    getters: {
        result: state => {
            return state.result
        },
        rowCount: state => {
            return state.rowCount
        },
        loading: state => {
            return state.loading
        }
        ,

    },
    mutations: {
        COMMIT_RESULT (state, result) {
            state.result = result
        },
        COMMIT_ROWCOUNT (state, rowCount) {
            state.rowCount = rowCount
        },
        COMMIT_LOADING (state, loading) {
            state.loading = loading
        },
    },
    actions: {
        async beginLoading ({ commit }) {
            commit('COMMIT_LOADING', true)
        },
        async saveResult ({ commit }, result) {
            console.log('snService saveResult called ')
            // await FlexSearchSn.addData(result.data);
            commit('COMMIT_RESULT', result.data)
            commit('COMMIT_ROWCOUNT', result.rowCount)
            commit('COMMIT_LOADING', false)
        },
    },
}
