import { set, toggle } from '@/utils/vuex'

export default {
  setRefreshRequired: set('refreshRequired'),
  setDomesticDataTable: set('domesticDataTable'),
  setDomesticDataTableLabels: set('domesticDataTableLabels'),
  setDomesticDataCategory: set('domesticDataCategory'),
  setDomesticVoiceCategory: set('domesticVoiceCategory'),
  setOverseasDataCategory: set('overseasDataCategory'),
  setNoUsageCategory: set('noUsageCategory'),
  setSelectedTab: set('selectedTab'),
  setSnFilter: set('snFilter'),
  setDefaultTopTenMonth: set('defaultTopTenMonth'),
}
